import * as React from "react";

function AdHostVertical(props) {
  return (
    <div>
        <h2>To place your Advertisements here, please contact us.</h2>
    </div>
    )
}

export default AdHostVertical;
