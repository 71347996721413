import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom'; // Add this

const Home = ({ username, setUsername, roomList, room, setRoom, socket, setIsLoggedIn }) => {
  const [enableJoinButton, setEnableJoinButton] = useState(false);
  const navigate = useNavigate(); // Add this

  useEffect(() => {
 
    var un = "";
    var rm = "";
    if (typeof(Storage) !== "undefined") {
      if (sessionStorage.username) {
        un = sessionStorage.username;
        // we got the username. now, we try to get the room
        if(sessionStorage.room) {
          rm = sessionStorage.room;
          setUsername(un);
          setRoom(rm);
        }
      }
    } else {
      // Sorry! No Web Storage support..
    }
  }, []);
 
  const response = (success) => {
   
  }

  const joinAfterConfirmation = () => {
    socket.emit('join_room', { username, room });
    setIsLoggedIn(true);
    // Redirect to /chat
    navigate('/chat', { replace: true });

  }

  const joinRoom = () => {
    if(username.trim() === "") {
      setEnableJoinButton(false);
      return;
    }

    document.getElementById("btnJoinRoom").style.cursor = "wait"; 
    
    if (room !== '' && username !== '') {
      // save the username and room to session storage
      sessionStorage.room = room;
      sessionStorage.username = username;
      sessionStorage.socket = socket;
      sessionStorage.isLoggedIn = true;
      
      var userData = {username, room};
      socket.emit('check_if_user_exists', userData, (response) => {
        document.getElementById("btnJoinRoom").style.cursor = "default"; 
        if(response === true) {
          alert("A user has already joined using this username.\nPlease select a different username.");
        } else {
          joinAfterConfirmation();
        }
      });
    }
  };


  const onUsernameChange = (e) => {
    let un = e.target.value;
    setUsername(un);
    if(un.trim() === "") {
      if(enableJoinButton !== false) {
        setEnableJoinButton(false);
      }
    }
    else {
      if(enableJoinButton !== true) {
        setEnableJoinButton(true);
      }
    }
  }

  const onInputKeyDown = (event) => {
    if(event.key === 'Enter'){
      joinRoom();
    }
  }

  return (<>
<div className={styles.container}>
      <div className={styles.formContainer}>
        <h1>Chat Jalsa</h1>
        
        <input
          autoFocus 
          onKeyDown={onInputKeyDown}
          className={styles.input}
          placeholder='Username...'
          onChange={onUsernameChange}
          value={username}
        />
        <select
          className={styles.input}
          onChange={(e) => setRoom(e.target.value)}
          defaultValue={room}
        >
          {roomList.map((name, i)=><option key={i}>{name}</option>)}
        </select>

        <button
          id='btnJoinRoom'
          className='btn btn-primary'
          style={{ width: '100%' }}
          onClick={joinRoom}
        >
          Join Room
        </button>
      </div>
    </div>

    </>
  );
};

export default Home;
