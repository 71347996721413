import {React, useState} from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ContactModal from './Modals/ContactModal'

const Footer = ({socket}) =>{
  const [isContactModalVisible, setContactModalState] = useState(false);

  const hideContactModal = () => {
    setContactModalState(false);
  };

  const showContactModal = (event) => {
    event?.preventDefault();
    setContactModalState(true);
  };

  const t = 
  <>
  <div style={{margin:'0em', color:"white"}}>
    <div class="container-fluid p-4 text-center" style={{margin:'0em'}}>
      <div class="row">
        <div class="col-sm-3" style={{textAlign:'left'}}>
          <h3>Chat Jalsa</h3>
        </div>
        <div class="col-sm-4" style={{textAlign:'left', margin:'0px'}}>
          <p style={{margin:'0em'}}>We are always eager to hear from you. Please feel free to <button type="button" class="btn btn-link" onClick={showContactModal} >Contact us</button></p>
        </div>
        <div class="col-sm-5" style={{textAlign:'right'}}>
          <p style={{margin:'0em'}}>
            &nbsp;&nbsp; &copy; 2024 Chat Jalsa. All Rights Reserved.
          </p> 
        </div>
      </div>
    </div>
  </div>
  <Modal open={isContactModalVisible} onClose={hideContactModal} center>
  <ContactModal isOpen={isContactModalVisible} onClose={hideContactModal} socket={socket} />
</Modal>
</>
  return t;
}

export default Footer;

