import styles from './styles.module.css';

const RoomAndUsers = ({ socket, username, room, withUser, onHandleWithUserSelected, conversations, setCloseConversationUserId }) => {
  const onUsernameClick = (u) => {
    onHandleWithUserSelected(u);
  }

  const onConversationClick = (conv) => {
    let username = conv.username;
    let id = conv.userId;
    let u = { username, id, room };

    onHandleWithUserSelected(u);
  }

  const closeConversation = (userId) => {
    setCloseConversationUserId(userId);
  }

let withUserUsername = "public";
if(withUser !== null) {
  if(withUser !== "") {
    withUserUsername = withUser.username;
  }
}

  const ConversationItem = (conv) => { 
    return <>
      <div onClick= {
          conv.userId === "public" ? (e)=>{e.preventDefault(); onUsernameClick("")} :
            (e)=>{e.preventDefault(); onConversationClick(conv)}
          } 
          style={{ background: 'rgb(63, 20, 255)', color:'white', width:'100%', cursor:'pointer'}}>
        <span 
          style={
            { fontWeight:`${conv.username === withUserUsername ? 'bolder' : 'normal'}`,
             color: `${conv.username === withUserUsername ? 'yellow' : 'white'}` 
            }
          } 
          >
          {conv.userId === "public" ? "Public Room" : conv.username} 
        </span>
        {conv.newMessages > 0 &&
        <>&nbsp;&nbsp;
        [ {conv.newMessages} ]
        &nbsp;&nbsp;</>
        }

        <span >
        {conv.userId !== "public" && 
        <button className={styles.CloseButton} style={{float:'right'}} onClick={(e)=>{closeConversation(conv.userId)}}>&times;</button>
        }
        </span>
      </div>
    </>
  }

  return (
    <div className={styles.roomAndUsersColumn}>
      <h2 className={styles.roomTitle}>{room}</h2> 
      <div>
        <h5 className={styles.usersTitle}>Conversations:</h5>
        <div className={styles.BoxWithScrollbars}>
          <ul className={styles.usersList}>
            {conversations.map((user) => (
              <li key={user.userId}>
                {ConversationItem(user)}
              </li>
            ))}
          </ul>
        </div>
      </div>
      
    </div>
  );
};

export default RoomAndUsers;
