import styles from './styles.module.css';
import React, { useState, useEffect, useRef } from 'react';
import InputEmoji from "react-input-emoji";

const SendMessage = ({ socket, username, room, withUser }) => {
  const [message, setMessage] = useState('');
  const withUserBackup = useRef(withUser);

  useEffect(() => {
    withUserBackup.current = withUser;
  }, [withUser]);

  useEffect(() => {
    socket.on('file_uploaded_successfully', (data) => {
      console.log("received file_uploaded_successfully");
      sendUploadedFileMessage(data);
    });

    // Remove event listener on component unmount
    return () => {
      socket.off('file_uploaded_successfully');
    }
  }, [socket]);

  const sendUploadedFileMessage = (data) => {
    var message = data.message;

    const __createdtime__ = Date.now();
    var senderUserId = socket.id;
    if(withUserBackup.current === null || withUserBackup.current === '') {
      // public room
      // Send message to server. We can't specify who we send the message to from the frontend. We can only send to server. Server can then send message to rest of users in room
      socket.emit('send_message', { username, senderUserId, room, message, __createdtime__ });
    } else {
      // private chat
      // Send message to server.
      var withUser = withUserBackup.current;
      socket.emit('send_private_message', { username, senderUserId, room, message, __createdtime__, withUser });
    }
  }

  const sendMessage = () => {
    if (message !== '') {
      const __createdtime__ = Date.now();
      var senderUserId = socket.id;
      if(withUserBackup.current === null || withUserBackup.current === '') {
        // public room
        // Send message to server. We can't specify who we send the message to from the frontend. We can only send to server. Server can then send message to rest of users in room
        socket.emit('send_message', { username, senderUserId, room, message, __createdtime__ });
      } else {
        // private chat
        // Send message to server.
        var withUser = withUserBackup.current;
        socket.emit('send_private_message', { username, senderUserId, room, message, __createdtime__, withUser });
      }

      setMessage('');
    }
  };

  const onInputKeyDown = (event) => {
    if(event.key === 'Enter'){
      sendMessage();
    }
  }

  const onEnterPressed = (text) => {
    sendMessage();
  }

  return (
    <div className={styles.sendMessageContainer}>
 <div class="row">
  <div className="col-sm-11" style={{color:'white'}}>
   
 
              <InputEmoji 
                autoFocus 
                // className={styles.messageInput}
                className="form-control" 
                value={message}
                onChange={setMessage}
                cleanOnEnter
                onEnter={onEnterPressed}
                placeholder="Type a message"
                />

</div>
  <div className="col-sm-1" style={{color:'white'}}>
    <div style={{verticalAlign:'bottom', textAlign:'left', margin:'13px -30px 0 -25px', padding:'0'}} >
      <button className={styles.btncircle} onClick={sendMessage}>
        <i class="bi bi-send"></i>
      </button>
    </div>
  </div>
</div> 
      </div>
    


  );
};

export default SendMessage;
