import './App.css';
import { useState, useEffect } from 'react';
import Home from './pages/home';
import Chat from './pages/chat';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import io from 'socket.io-client';
import Navbar from './components/Layout/Navbar';
import JsonData from './rooms.json';

const socket = io.connect('www.chatjalsa.com');

function App() {
  const [username, setUsername] = useState('');
  const [room, setRoom] = useState('');
  const [roomList, setRoomList] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // check session storage for username and room
  // this will be required to prevent user getting logged out of the room
  // when the browser is refreshed
  
  useEffect(() => {
    let roomListString = JsonData.Rooms;
    let roomListArr = roomListString.split(",");
    setRoomList(roomListArr);
    setRoom(roomListArr[0]);
  }, []);  

  return (
    <>
    <Router>
      <div className='App'>
      <Navbar 
        username={username}
        socket={socket}
        room={room}
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
      />

        <Routes>
          <Route
            path='/'
            element={
              <Home
                username={username}
                setUsername={setUsername}
                roomList={roomList}
                room={room}
                setRoom={setRoom}
                socket={socket}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          {/* Add this */}
          <Route
            path='/chat'
            element={
            <Chat 
              username={username}
              room={room} 
              socket={socket} 
              setRoom={setRoom}
              isLoggedIn={isLoggedIn}
              />}
          />
        </Routes>
      </div>
    </Router>
    </>
  );
}

export default App;
