import styles from './styles.module.css';
import { useState, useEffect } from 'react';
import ReactScrollableList from 'react-scrollable-list';

var uploader;
var SocketIOFileUpload = require('socketio-file-upload');
var usersInRoom = [];
const UserList = ({ socket, username, room, withUser, onHandleWithUserSelected }) => {
  const [roomUsers, setRoomUsers] = useState([]);
  const [userFilter, setUserFilter] = useState('');

  useEffect(() => {
    socket.on('chatroom_users', (data) => {
      console.log(data);
      setRoomUsers(data);
    });

    uploader = new SocketIOFileUpload(socket);
    uploader.listenOnInput(document.getElementById("siofu_input"));
    
    return () => socket.off('chatroom_users');
  }, [socket]);


  const onUsernameClick = (u) => {
    onHandleWithUserSelected(u);
  }

  usersInRoom = [];
  for (let i = 0; i < roomUsers.length; i++) {
    if(roomUsers[i].username === username) {
      // usersInRoom.push({ id: roomUsers[i].id, content: <p>{roomUsers[i].username}</p> });
    } else {
      usersInRoom.push({ id: roomUsers[i].id, username:roomUsers[i].username, content: <p onClick={(e)=>{e.preventDefault(); onUsernameClick(roomUsers[i]);}}>{roomUsers[i].username}</p> });
    }
  }

  function compare( a, b ) {
    if ( a.username < b.username ){
      return -1;
    }
    if ( a.username > b.username ){
      return 1;
    }
    return 0;
  }

  usersInRoom.sort(compare);

  function filterUsers(array, string) {
    return array.filter(o =>
            o.username.toLowerCase().includes(string.toLowerCase()));
  }

  usersInRoom = filterUsers(usersInRoom, userFilter);

  return (<>
    <div className={styles.roomAndUsersColumn}>
      <h2 className={styles.roomTitle}><br/></h2> 
      <div>
        <span style={{fontWeight:'bold', color:'white'}}>Other users in {room}:</span>
        <div>
          <input placeholder='Search user...' style={{width:'80%', borderRadius:'5px'}} onChange={(e)=>{ setUserFilter(e.target.value)}} />
        </div>
        <div style={{marginTop:'6px'}}>
        <ReactScrollableList 
            listItems={usersInRoom}
            heightOfItem={30}
            maxItemsToRender={20}
            style={{ color: 'white', fontWeight:'bolder', cursor:'pointer' }}
        />
        </div>
      </div>
    </div>
    <div>
      <br />
      <h5 style={{color:'white'}} >Send file to chat:</h5>
      <input type="file" id="siofu_input" placeholder='Upload file...' />
    </div>
    </>
  );
};

export default UserList;
