import styles from './styles.module.css';
import { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';

var messageList = [];
const Messages = ({ socket, withUser, setConversations, closeConversationUserId, setConversationsAndUser, setCloseConversationUserId, setWithUser, room, onHandleWithUserSelected, loggedInUsername}) => {
  const [messagesRecieved, setMessagesReceived] = useState([]);
  const [someText, setSomeText] = useState(0);

  useEffect(() =>
    {
      applyMessageListToMessagesReceived();
    }, [withUser]);

  useEffect(() =>
    {
      if(closeConversationUserId === '') return;

      // remove conversation with id = closeConversationUserId from messageList
      let index = -1;
      for(let i = 0; i < messageList.length; i++) {
        if(messageList[i].userId === closeConversationUserId) {
          // remove this item from the list.
          index = i;
        }
      }

      if(index > -1) {
        messageList.splice(index, 1);
      }

      let conv = [];
      for(let i = 0; i < messageList.length; i++) {
        conv.push({id:i, username: messageList[i].username, userId:messageList[i].userId, newMessages:messageList[i].newMessages});
      }
      // setConversations(conv);
      let username = "";
      let id = "";
      let u = { username, id, room };

      if(messageList.length > 0) {
        let newConvIndex = index;

        if(newConvIndex > (messageList.length - 1)) {
          newConvIndex = messageList.length - 1;
        }

        username = messageList[newConvIndex].username;
        id = messageList[newConvIndex].id;

        if(id === "") {
          id = "public"
        }

        if(id === undefined) {
          username = 'public';
          id = 'public';
        }

        u = { username, id, room };

        setConversations(conv);
        if(username !== "public") {
          onHandleWithUserSelected(u);
        } else {
          onHandleWithUserSelected('');
        }
        setWithUser(u);
        setCloseConversationUserId("");

      } else if(messageList.length === 0) {
        // the last item got deleted..
        setConversations([]);
        setWithUser(u);
        setCloseConversationUserId("");
      }
    }, [closeConversationUserId]);

  useEffect(() =>
    {
      applyMessageListToMessagesReceived();
    }, [messageList]);

  const messagesColumnRef = useRef(null);

  const applyMessageListToMessagesReceived = () => {

    if(messageList === null) {
      setMessagesReceived([]);
      setConversations([])
      return;
    }

    var result = null;
    var targetId = "public";

    if(withUser === undefined || withUser === null) {
      // public chat
      result = messageList.filter((m)=>{return m.userId === "public"});
    } else if(withUser === "") {
      // public chat
      result = messageList.filter((m)=>{return m.userId === "public"});
    } else if (withUser.id === undefined) {
      // public chat
      result = messageList.filter((m)=>{return m.userId === "public"});
    } else {
      // private chat
      var targetId = withUser.id;
      result = messageList.filter((m)=>{ return m.userId === withUser.id });
    }
    
    for(let j = 0; j < messageList.length; j++) {
      if(messageList[j].userId === targetId) {
        messageList[j].newMessages = 0;
      }
    }

    let conv = [];
    for(let i = 0; i < messageList.length; i++) {
      conv.push({id:i, username: messageList[i].username, userId:messageList[i].userId, newMessages:messageList[i].newMessages});
    }
    setConversations(conv);

    if(result === null || result.length === 0) {
      setMessagesReceived([]);
    } else {
      setMessagesReceived(result[0].messages);
    }
  }

  const getDBEquivalentObject = (data) => {
    var time_stamp = data.__createdtime__;
    var message = data.message;
    var room = data.room;
    var username = data.username;

    return {time_stamp, message, room, username};
  }

  const addToMessagesList = (data) => {
    var msg = getDBEquivalentObject(data);

    let key = getKeyUserId(data);
    let userId = key.id;
    let keyUsername = key.username;

    if(messageList === null || messageList.length === 0) {
      var obj = {
        userId: userId,
        username: keyUsername,
        newMessages:1,
        messages: [msg]
      };
      messageList = [obj];
    } else {
      var ml = JSON.parse(JSON.stringify(messageList));
      var gotId = false;
      ml.forEach((element) => {
        if(element.userId === userId) {
          element.messages.push(msg);
          element.newMessages=element.newMessages+1;
          gotId = true;
        }
      });

      if(gotId === false) {
        obj = {
          userId: userId,
          username: keyUsername,
          newMessages:1,
          messages: [msg]
        };
        ml.push(obj);
      }

      messageList = JSON.parse(JSON.stringify(ml));
    }

    applyMessageListToMessagesReceived();

    refresh();
  }

  const refresh = () => {
    if(someText === '') {
      setSomeText(' ');
    } else {
      setSomeText('');
    }
  }
  // Runs whenever a socket event is recieved from the server - public message
  useEffect(() => {
    socket.on('receive_message', (data) => {
      addToMessagesList(data);
    });

    socket.on('receive_private_message', (data) => {
      addToMessagesList(data);
    });

    // Remove event listener on component unmount
    return () => {
      socket.off('receive_message');
      socket.off('receive_private_message');
    }
  }, [socket]);

  useEffect(() => {
    // Last 100 messages sent in the chat room (fetched from the db in backend)
    socket.on('last_100_messages', (last100Messages) => {
      last100Messages = JSON.parse(last100Messages);

      var msg = "------------------------------------------" + 
        "<br/>Welcome " + loggedInUsername + "<br/>" + 
        "------------------------------------------";

      last100Messages.push({id:'-1', time_stamp: Date.now(), message: msg, room: room, username: ""});

      // Sort these messages by __createdtime__
      last100Messages = sortMessagesByDate(last100Messages);
      // setMessagesReceived((state) => [...last100Messages, ...state]);

      var obj = {
        userId: "public",
        messages: last100Messages
      };
      messageList = [obj];
      applyMessageListToMessagesReceived();
    });

    return () => socket.off('last_100_messages');
  }, [socket]);


  // Scroll to the most recent message
  useEffect(() => {
    messagesColumnRef.current.scrollTop =
      messagesColumnRef.current.scrollHeight;
  }, [messagesRecieved]);

  function sortMessagesByDate(messages) {
    return messages.sort(
      (a, b) => parseInt(a.__createdtime__) - parseInt(b.__createdtime__)
    );
  }

  // dd/mm/yyyy, hh:mm:ss
  function formatDateFromTimestamp(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleString();
  }


  // return the userId that could be used as a key
  const getKeyUserId = (msgData) => {
    var k = "public";
    var un = "public";
    var id = k;
    var username = un;

    if(msgData.withUser === undefined || msgData.withUser === "") {
      // public chat
      return {id, username};
    }

    var u1 = msgData.withUser.id;
    var un1 = msgData.withUser.username;

    var u2 = msgData.senderUserId;
    var un2 = msgData.username;

    let _loggedInUserId = socket.id;

    if(_loggedInUserId === u1) {
      k = u2;
      un = un2;
    } else if(_loggedInUserId === u2) {
      k = u1;
      un = un1;
    }

    if(k === "") {
      k = "public";
      un = "public";
    }

    id = k;
    username = un;

    return {id, username};
  }

  const Linkify = (inputText) => {
    if(inputText === undefined || inputText === null) return '';

    //URLs starting with http://, https://, or ftp://
    var replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    var replacedText = inputText.replace(replacePattern1, '<a style="color: cyan;" href="$1" target="_blank">$1</a>');

    //URLs starting with www. (without // before it, or it'd re-link the ones done above)
    var replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a style="color: cyan;" href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links
    var replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
    replacedText = replacedText.replace(replacePattern3, '<a style="color: cyan;" href="mailto:$1">$1</a>');

    return replacedText
  }

  const getParsedMsg = (msg) => {
    var t = Linkify(msg);
    return <span style={{color:'white'}}>{parse(t)}</span>
  }

  var previousUser = "";
  const getMessageRow = (user, msg, time) => {
    console.log("previousUser = " + previousUser);
    var usr = user;
    console.log("usr = " + usr);
    console.log("user = " + user);

    if(usr === previousUser) {
      console.log("usr === previousUser = true");
      usr = "";
    } else {
      console.log("usr === previousUser = false");
      previousUser = usr;
    }
    console.log("Finally, usr = " + usr);
    
    return <>
      <div class="row">
        <div style={{color:'white'}} class="col-sm-2">{usr}</div>
        <div style={{color:'white'}} class="col">{msg}</div>
        <div className={["col-sm-auto", styles.msgMeta].join(' ')}>{time}</div>
      </div> 
  </>
  }

  return (
    <>
    <div>{(withUser === null || withUser === "" || withUser.id === "public" || withUser.id ==="") ? <h2 className={styles.roomTitle}>Public{someText}</h2> : <h2 className={styles.roomTitle}>{withUser.username}{someText}</h2>}</div>
    <div className={styles.messagesColumn} ref={messagesColumnRef}>
      {messagesRecieved.map((msg, i) => (
        <div  key={i} style={{padding:'0px'}}>
          {getMessageRow(msg.username, getParsedMsg(msg.message), formatDateFromTimestamp(msg.time_stamp))}
        </div>
      ))}
    </div>
    </>
  );
};

export default Messages;
