import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import RoomAndUsersColumn from './room-and-users';
import UserList from './UserList';
import SendMessage from './send-message';
import MessagesReceived from './messages';
import { useNavigate } from 'react-router-dom'; 
import LayoutElement from '../../components/Layout/LayoutElement';
import AdHostVertical from '../../components/AdHosts/AdHostVertical';
import Footer from '../../components/Footer';

const Chat = ({ username, room, socket, isLoggedIn }) => {
  const navigate = useNavigate(); 
  const [withUser, setWithUser] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [closeConversationUserId, setCloseConversationUserId] = useState("");

  

  const forceLeaveRoom = () => {
    // console.log("doing forceLeaveRoom");
    const __createdtime__ = Date.now();
    socket.emit('leave_room', { username, room, __createdtime__ });
    // Redirect to home page
    // console.log("about to redirect");
    navigate('/', { replace: true });
    // console.log("redirection complete");
  };

  useEffect(() =>{
    if(isLoggedIn === false) {
      forceLeaveRoom();
      return;
    }

    if (typeof(Storage) !== "undefined") {
      if (sessionStorage.forceLeave) {
        sessionStorage.removeItem("forceLeave");
        forceLeaveRoom();
      }
    } else {
      // Sorry! No Web Storage support..
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    };
    const handleUnload = (event) => {
      event.preventDefault();
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
      sessionStorage.forceLeave = true;
      return true;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  const onHandleWithUserSelected = (withUserSelected) => {
    setWithUser(withUserSelected);
  }

    
  return (<>
    <div className={styles.container}>
   <LayoutElement ss = {[
      {Columns: 2, Element: 
        // <div className="min-vh-100 d-flex flex-column">
        <div className="d-flex flex-column">
          <RoomAndUsersColumn 
          socket={socket} 
          username={username} 
          room={room} 
          onHandleWithUserSelected={onHandleWithUserSelected} 
          withUser={withUser} 
          conversations={conversations}
          setCloseConversationUserId={setCloseConversationUserId}
          /> 
        </div>
      },
      {Columns: 6, Element: 
        // <div style={{height:'100%', paddingBottom:'10%'}}>
        <div>
          <div className='container-fluid d-flex h-100 flex-column'>
            <MessagesReceived loggedInUsername={username}  socket={socket} withUser={withUser} setConversations={setConversations} closeConversationUserId={closeConversationUserId} setCloseConversationUserId={setCloseConversationUserId} setWithUser={setWithUser} room={room} onHandleWithUserSelected={onHandleWithUserSelected} />
            <SendMessage socket={socket} username={username} room={room} withUser={withUser} />
          </div>
        </div>
       },
       {Columns: 2, Element: 
        // <div className="min-vh-100 d-flex flex-column">
        <div className="d-flex flex-column">
          <UserList socket={socket} username={username} room={room} onHandleWithUserSelected={onHandleWithUserSelected} withUser={withUser} /> 
        </div>
      },
      {Columns: 2, Element: <AdHostVertical /> },
    ]} />

    </div>
    <div style={{background:'navy'}}>
      <Footer socket={socket} />
    </div>
    
    </>
  );
};

export default Chat;
