import React from 'react';
import {useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

var loggedIn = false;
function Navbar({username, socket, room, isLoggedIn, setIsLoggedIn}) {
  const navigate = useNavigate();
  

  const leaveRoom = () => {
    const __createdtime__ = Date.now();
    socket.emit('leave_room', { username, room, __createdtime__ });
    setIsLoggedIn(false);
    // Redirect to home page
    navigate('/', { replace: true });
  };
  
  return (
<>
    <nav class="navbar navbar-expand-sm bg-dark navbar-dark">
      <div class="container-fluid">

        <label class="navbar-brand" style={{fontWeight:'bold'}} >ChatJalsa.com</label>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="mynavbar">
          <ul class="navbar-nav me-auto">
          </ul>
            <nav class="main-nav float-right d-none d-lg-block  navbar-text">
              {isLoggedIn ? 
              <span>
                <label style={{fontWeight:'bold'}} >{username}</label> &nbsp;&nbsp;
                <button type="button" class="btn btn-outline-info" onClick={leaveRoom}>Leave</button>
              </span>
              : <></>
            }
            </nav>
        </div>
      </div>
    </nav>
    </>);
}

export default Navbar;
